import { DateTime, Interval } from 'luxon';
import moment from 'moment';
import {
  minEndTimeForListing,
  minStartTimeForListing,
} from 'now-shared/validation/listing-validation';
import {
  setDateToNonOpWellsZone,
  toAuctionEventTime,
} from 'now-shared/helpers/time-helpers';

export const DEFAULT_QUERY = '?page=1';

export const createQuery = (history, queryName, queryValue) => {
  const searchParams = new URLSearchParams(
    history.location.search || DEFAULT_QUERY,
  );

  if (queryName && queryValue) {
    const id
      = typeof queryValue === 'string'
      && +queryValue.toLowerCase().replace(/listing-/g, '');
    if (id) {
      searchParams.set(queryName, id);
    } else {
      searchParams.set(queryName, queryValue);
    }
  }

  if (queryName === 'state') {
    searchParams.delete('basin');
    searchParams.delete('county');
  }

  if (queryName === 'basin') {
    searchParams.delete('county');
  }

  if (queryName !== 'page') {
    searchParams.set('page', 1);
  }

  return `?${searchParams.toString()}`;
};

export const createCustomQuery = (history, queryName, queryValue) => {
  const searchParams = new URLSearchParams(
    history.location.search || DEFAULT_QUERY,
  );

  if (queryName && queryValue) {
    const id
      = typeof queryValue === 'string'
      && +queryValue.toLowerCase().replace(/listing-/g, '');
    if (id) {
      searchParams.set(queryName, id);
    } else {
      searchParams.set(queryName, queryValue);
    }
  }

  if (queryName !== 'page') {
    searchParams.set('page', 1);
  }

  return `?${searchParams.toString()}`;
};

export const removeQueryParam = (history, queryName) => {
  const searchParams = new URLSearchParams(
    history.location.search || DEFAULT_QUERY,
  );

  if (queryName) {
    searchParams.delete(queryName);
  }

  if (!searchParams.page) {
    searchParams.set('page', 1);
  }

  return `?${searchParams.toString()}`;
};

export const formattedPhoneToNumberString = formatted => formatted.replace(/[^+\d]/g, '');

export const existingPropertyDateRangeGenerator = (startDate, endDate) => {
  const minStartDate = minStartTimeForListing();
  const minEndDate = minEndTimeForListing(minStartDate);

  const currentEndDate = toAuctionEventTime(
    new Date(endDate),
    'end',
  ).toJSDate();

  const startTime = minStartDate;
  const endTime = moment(currentEndDate).isBefore(minEndDate)
    ? minEndDate
    : currentEndDate;

  return {
    startTime,
    endTime,
  };
};

export const checkWellsFields = ({
  landingZone,
  surfaceLatitude,
  surfaceLongitude,
  workingInterestPercentage,
  netRevenueInterestNumber,
}) => Boolean(
  landingZone
      && surfaceLatitude
      && surfaceLatitude !== '-_'
      && surfaceLongitude
      && surfaceLongitude !== '-_'
      && workingInterestPercentage
      && netRevenueInterestNumber,
);

export const checkWellsCoordinates = ({
  surfaceLatitude,
  surfaceLongitude,
  bottomLatitude,
  bottomLongitude,
}) => Boolean(
  surfaceLatitude
      && surfaceLatitude !== '-_'
      && surfaceLongitude
      && surfaceLongitude !== '-_'
      && bottomLatitude
      && bottomLatitude !== '-_'
      && bottomLongitude
      && bottomLongitude !== '-_',
);

export const coordinatesGenerator = wells => {
  const polylinesCoordinates = [];
  const markersCoordinates = [];
  if (!wells) return { polylinesCoordinates, markersCoordinates };

  wells.forEach(zone => {
    const allFieldsFilled = checkWellsCoordinates(zone);
    if (allFieldsFilled) {
      polylinesCoordinates.push([
        { lat: +zone.surfaceLatitude, lng: +zone.surfaceLongitude },
        { lat: +zone.bottomLatitude, lng: +zone.bottomLongitude },
      ]);

      markersCoordinates.push(
        { lat: +zone.surfaceLatitude, lng: +zone.surfaceLongitude },
        // { lat: +zone.bottomLatitude, lng: +zone.bottomLongitude },
      );
    }
  });

  return { polylinesCoordinates, markersCoordinates };
};

export const formatStringToFirstCapitalLetter = string => {
  const newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const formatStringToTitleCase = string => {
  const newString = string
    .split(' ')
    .map(word => formatStringToFirstCapitalLetter(word))
    .join(' ');
  return newString;
};

export const getCountdownValue = endTime => {
  const startDate = DateTime.local();
  let endDate = DateTime.fromISO(endTime);
  let timeIsUp = false;
  if (endDate <= startDate) {
    endDate = startDate;
    timeIsUp = true;
  }
  return {
    text: Interval.fromDateTimes(startDate, endDate)
      .toDuration()
      .toFormat("dd'D' hh'H' mm'M'"),
    timeIsUp,
  };
};

export function getDocumentExtensionFromS3Key(key) {
  return key?.match(/\.[^.]+$/)?.[0];
}

export function decimalCount(num) {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
}

export function isObjectEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}

export function getListingAnalyticsDateRange({ listing, currentDate }) {
  let usingWeeks = false;
  const rightNow = moment(setDateToNonOpWellsZone(currentDate ?? new Date()));
  let listingCreatedAtTime;
  let listingEndTime;
  let createdAt;
  let closeTime;
  let endTime;
  let days = 0;
  if (listing?.createdAt) {
    listingCreatedAtTime = moment(setDateToNonOpWellsZone(listing.createdAt));
    listingEndTime = moment(setDateToNonOpWellsZone(listing.endTime));
    createdAt = listingCreatedAtTime.clone().startOf('day');
    closeTime = moment(
      setDateToNonOpWellsZone(
        listing.closedAt
          || (listing.archivedAt
          && new Date(listing.archivedAt) < new Date(listing.endTime)
            ? listing.archivedAt
            : listing.endTime),
      ),
    );
    endTime = closeTime.isAfter(rightNow) ? rightNow : closeTime;
    days = endTime.clone().startOf('day').diff(createdAt, 'days') + 1;
    if (days > 42) {
      // dates should begin on week boundaries (starting on Monday) up to and including
      // endTime date
      usingWeeks = true;
      const weekStartDay = 1;
      const daysInWeek = 7;
      let daysToGoBack = createdAt.day() - weekStartDay;
      if (daysToGoBack < 0) {
        daysToGoBack = daysInWeek + daysToGoBack;
      }
      createdAt = createdAt.clone().startOf('day').add(-daysToGoBack, 'days');
      // only track up to 12 weeks of data
      const maxDate = createdAt
        .clone()
        .add(12, 'weeks')
        .subtract(moment.duration('00:00:00.001'));
      if (endTime.isAfter(maxDate)) {
        endTime = maxDate;
      }
    }
  }
  return {
    rightNow,
    listingCreatedAtTime,
    listingEndTime,
    createdAt,
    endTime,
    closeTime,
    usingWeeks,
    days,
  };
}

export function filterOutListingAnalyticsEventsOutsideDateRange({
  events,
  dateRange,
}) {
  let result;
  if (dateRange?.createdAt) {
    const createdAt = dateRange.createdAt.toDate();
    const endTime = dateRange.endTime.toDate();
    result = events.filter(evt => {
      const eventDate = evt.date;
      return eventDate >= createdAt && eventDate <= endTime;
    });
  } else {
    result = [...events];
  }
  return result;
}

export const getAnalyticsDownloadsForCompany = downloads => {
  const analyticsDownloadGroupingThresholdMillis = 500;
  // ignore duplicate file download within 1 minute
  const duplicateDownloadThresholdMillis = 1000 * 60;
  const result = [];
  let lastDownloadGroup = [];
  let lastDownload;
  const lastDocumentDownloaded = {};
  downloads
    .map(download => ({ ...download, date: new Date(download.date) }))
    .sort((a, b) => a.date - b.date)
    .forEach(download => {
      const wasDownloadedRecently
        = download.date
          - (lastDocumentDownloaded[download.documentId] ?? new Date(0))
        <= duplicateDownloadThresholdMillis;
      if (
        lastDownload
        && download.date - lastDownload.date
          <= analyticsDownloadGroupingThresholdMillis
        && !lastDownloadGroup.find(d => d.documentId === download.documentId)
      ) {
        lastDownloadGroup.push(download);
        if (!wasDownloadedRecently) {
          lastDocumentDownloaded[download.documentId] = download.date;
        }
      } else if (wasDownloadedRecently) {
        // ignore download because it was already downloaded recently enough to be considered a duplicate
      } else {
        lastDownloadGroup = [download];
        result.push({
          ...download,
          downloadGroup: lastDownloadGroup,
        });
        lastDocumentDownloaded[download.documentId] = download.date;
      }
      lastDownload = download;
    });
  return result;
};

export function enhanceListingAnalytics({ listing, analytics, currentDate }) {
  const dateRange = getListingAnalyticsDateRange({ listing, currentDate });
  let enhancedAnalytics;
  if (analytics) {
    const enhancedCompanies = analytics.companies.map(company => ({
      ...company,
      views: filterOutListingAnalyticsEventsOutsideDateRange({
        dateRange,
        events: company.views.map(evt => ({
          ...evt,
          date: setDateToNonOpWellsZone(evt.date),
        })),
      }),
      downloads: getAnalyticsDownloadsForCompany(
        filterOutListingAnalyticsEventsOutsideDateRange({
          dateRange,
          events: company.downloads.map(evt => ({
            ...evt,
            date: setDateToNonOpWellsZone(evt.date),
          })),
        }),
      ),
    }));
    enhancedAnalytics = {
      ...analytics,
      companies: enhancedCompanies,
      totalViews: enhancedCompanies
        .map(company => company.views.length)
        .reduce((prev, curr) => prev + curr, 0),
      totalDownloads: enhancedCompanies
        .map(company => company.downloads.length)
        .reduce((prev, curr) => prev + curr, 0),
      totalBids: enhancedCompanies.filter(company => company.hasBid).length,
    };
  }
  return {
    dateRange,
    enhancedAnalytics,
  };
}

export function getListingAnalyticsGraphData({
  analytics,
  dateRange,
  filterByCompany,
}) {
  const filteredData
    = filterByCompany?.id !== undefined
      ? analytics?.companies?.filter(
        company => company.id === filterByCompany.id,
      )
      : analytics?.companies;

  const listingDates = [];

  if (dateRange.createdAt) {
    let currentDate = dateRange.createdAt;
    const { endTime } = dateRange;
    if (!dateRange.usingWeeks) {
      while (currentDate.isSameOrBefore(endTime)) {
        const nextDate = currentDate.clone().add(1, 'days');
        listingDates.push({
          date: currentDate.toDate(),
          nextDate: nextDate.toDate(),
          totalViews: 0,
          totalDownloads: 0,
        });
        currentDate = nextDate;
      }
    } else {
      while (currentDate.isSameOrBefore(endTime)) {
        const nextDate = currentDate.clone().add(1, 'weeks');
        listingDates.push({
          date: currentDate.toDate(),
          nextDate: nextDate.toDate(),
          totalViews: 0,
          totalDownloads: 0,
        });
        currentDate = nextDate;
      }
    }
  }

  let data = filteredData
    ?.map(company => company.views)
    .flat()
    .reduce(
      (prev, curr) => {
        const eventDate = curr.date;
        const dateAlreadyExistsIndex = prev.findIndex(
          item => eventDate >= item.date && eventDate < item.nextDate,
        );
        let result = prev;
        if (dateAlreadyExistsIndex !== -1) {
          result = [...prev];
          result[dateAlreadyExistsIndex] = {
            ...prev[dateAlreadyExistsIndex],
            totalViews: prev[dateAlreadyExistsIndex].totalViews + 1,
          };
        }
        return result;
      },
      [...listingDates],
    );

  data = filteredData
    ?.map(company => company.downloads)
    .flat()
    .reduce(
      (acc, event) => {
        const eventDate = event.date;
        const dateAlreadyExistsIndex = acc.findIndex(
          item => eventDate >= item.date && eventDate < item.nextDate,
        );
        if (dateAlreadyExistsIndex !== -1) {
          acc[dateAlreadyExistsIndex] = {
            ...acc[dateAlreadyExistsIndex],
            totalDownloads: acc[dateAlreadyExistsIndex].totalDownloads + 1,
          };
        }
        return acc;
      },
      [...data],
    )
    .sort((a, b) => a.date - b.date);

  return data;
}

export function filterByDate(companies, date, usingWeeks) {
  // Helper function to check if a date is within the given day or week
  const isDateWithinDuration = checkDate => {
    if (usingWeeks) {
      const startOfWeek = moment(date).startOf('week');
      const endOfWeek = moment(date).endOf('week');
      return moment(checkDate).isBetween(startOfWeek, endOfWeek, 'day', '[]');
    }
    return moment(checkDate).isSame(date, 'day');
  };
  // If a date is selected, filter the views and downloads table by date
  if (!date) return companies;
  const result = companies.map(company => ({
    ...company,
    views: company.views.filter(view => isDateWithinDuration(view.date)),
    downloads: company.downloads.filter(download => isDateWithinDuration(download.date)),
  }));
  return result;
}
