import { action, asyncAction } from 'now-frontend-shared/utils/actions';

// async actions
export const getPropertiesMarkers = asyncAction('map/GET_PROPERTIES_MARKERS');
export const getHistoricalListings = asyncAction('map/GET_HISTORICAL_LISTINGS');
export const getNewMexicoListings = asyncAction('map/GET_NEW_MEXICO_LISTINGS');
export const getNewMexicoListingDocuments = asyncAction(
  'map/GET_NEW_MEXICO_LISTING_DOCUMENTS',
);

// sync actions
export const clearState = action('map/CLEAR_STATE');
